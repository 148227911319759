import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import RewardsDelegation from "../components/RewardsDelegation.vue";
import RatingGraph from "../components/RatingGraph.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/rewards-delegation",
    name: "rewardsDelegation",
    component: RewardsDelegation
  },
  {
    path: "/rating-graph",
    name: "ratingGraph",
    component: RatingGraph
  }
];

const router = new VueRouter({
  mode: "history",
  base: /*location?.origin.startsWith('https://alpha.archmage.app') ? "/" : "/config/"*/ "/",
  routes
});

export default router;