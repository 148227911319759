<template>
  <div id="app">
    <b-container>
      <b-row class="justify-content-md-center mt-4 mb-4">
        <b-col col md="8">
          <b-card header="Archmage Config" header-bg-variant="primary" header-text-variant="white" v-if="step === 1">
            <b-card-text>
              <b-alert show variant="danger" v-if="error">{{error}}</b-alert>
              <b-form @submit="onSubmit" inline class="justify-content-center mb-2">
                <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0 text-center">
                  <b-form-input id="inline-form-input-username" placeholder="Username" v-model="user.account.name" @keydown.enter.native="nextStep"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="nextStep">Load</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
              <b-alert show variant="warning" v-if="warning">
                <div class="d-flex">
                  <b-icon icon="exclamation-triangle-fill" scale="2" variant="warning" class="mr-3"></b-icon>
                  <div>
                    <p>Warning - No config found for user {{user.account.name}}.</p>
                    <p>You can continue to the next step to add a new configuration for this user. Please contact us in Discord to set up the private key for this new account. Until we have set up a private key the account will not be played.</p>
                    <b-button variant="primary" @click="create">Create</b-button>
                  </div>
                </div>
              </b-alert>
              <b-container class="mt-4" v-if="previousConfigs.length > 0">
                <b-row>
                  <b-col md="6" offset-md="3">
                    <b-card header="Previous Configs" header-bg-variant="primary" header-text-variant="white">
                      <b-card-text>
                        <b-list-group>
                          <b-list-group-item v-for="config in previousConfigs" :key="config">
                            <div class="d-flex justify-content-between">
                              <p class="mb-0">{{config}}</p>
                              <p class="mb-0"><b-link class="config-link" @click="nextStep(config)">Load</b-link></p>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-text>
          </b-card>

          <b-card header="Archmage Config" header-bg-variant="primary" header-text-variant="white" v-if="step === 2">
            <b-form @submit="onSubmit">
              <b-card-body class="pb-0 pt-2">
                <b-card-title>Account</b-card-title>
                <b-card-text>
                    <b-form-group
                      id="accountName-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Name"
                      label-for="accountName"
                    >
                      <div id="accountName" class="mt-2">@{{user.account.name}}</div>
                    </b-form-group>
                    <b-form-group
                      id="enabled-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Enabled"
                      label-for="enabled"
                    >
                      <b-form-checkbox v-model="user.account.enabled" name="enabled" switch class="mt-2" />
                    </b-form-group>
                    <b-form-group
                    id="postingKey-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Posting Key"
                    label-for="postingKey"
                  >
                    <div v-if="userStatus && userStatus.account && userStatus.account.postingKey && userStatus.account.postingKey.hasRecord" class="mt-2"><img  src="../assets/thumbsup.png" width="20" height="20"/>Correct posting key saved</div>
                    <div v-if="userStatus && !(userStatus.account && userStatus.account.postingKey && userStatus.account.postingKey.hasRecord)">
                      <b-input-group class="mb-2 mr-sm-2 mb-sm-0 text-center">
                        <b-form-input id="postingKey" placeholder="Private Posting Key" v-model="postingKey" type="password"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" @click="savePostingKey">Save</b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <b-alert show variant="danger" class="mt-2">
                        <img src="../assets/x.png" width="15" height="15"/>
                        No posting key saved for account. Please add the private posting key then hit save. Note: the posting key usually starts with a 5.
                      </b-alert>
                    </div>
                  </b-form-group>
                    <b-form-group
                      id="tokenType-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Token Type"
                    >
                      <div v-if="tokenType === 'ARCHMAGEA'" class="mt-2"><img src="../assets/archmagea.png" width="20px" height="20px" /> ARCHMAGEA</div>
                      <div v-if="tokenType === 'ARCHMAGEB'" class="mt-2"><img src="../assets/archmageb.png" width="20px" height="20px" /> ARCHMAGEB</div>
                      <div v-if="tokenType === 'ARCHMAGE'" class="mt-2"><img src="../assets/archmage.png" width="20px" height="20px" /> ARCHMAGE</div>
                      <div v-if="tokenType === 'NONE'" class="mt-2">None</div>
                    </b-form-group>
                    <b-form-group
                      id="feeTable-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="9"
                      label="Fees"
                      label-for="feeTable"
                    >
                      <b-table small :fields="fields" :items="items" responsive="sm" striped v-if="!delegationOutOfRange">
                        <template #cell(action)="data">
                            <b-button :variant="data.item.required === data.item.current ? 'danger' : 'primary'" v-if="hasHiveKeychain && (data.item.required !== data.item.current || data.item.required !== 0)" @click="submitHiveKeychainDelegation(data.item.required === data.item.current ? 0 : data.item.required, data.item.type.toLowerCase(), data.item.current)" size="sm" class="m-1"><b-spinner v-if="awaitingDelegationUpdate[data.item.type.toLowerCase()].value" variant="light" label="Waiting" small/><img v-if="!awaitingDelegationUpdate[data.item.type.toLowerCase()].value" src="../assets/keychain-white.png" width="20px" height="20px" />Keychain</b-button>
                            <!--b-button :variant="data.item.required === data.item.current ? 'danger' : 'primary'" v-if="data.item.required !== data.item.current || data.item.required !== 0" @click="submitHivesignerDelegation(data.item.required === data.item.current ? 0 : data.item.required, data.item.type.toLowerCase(), data.item.current)" size="sm" class="m-1"><img src="../assets/signer-white.png" width="20px" height="20px" />Hivesigner</b-button-->
                        </template>
                        <template #cell(required)="data">
                          {{ data.value }}%
                        </template>
                        <template #cell(icon)="data">
                          <img v-if="data.item.required !== data.item.current && (!['Wild'].includes(data.item.type) || data.item.type === user.play.format)" src="../assets/x.png" width="15" height="15"/>
                          <img v-if="data.item.required === data.item.current" src="../assets/thumbsup.png" width="20" height="20"/>
                        </template>
                        <template #cell(current)="data">
                          <b v-if="data.item.required > data.item.current || data.item.required < data.item.current" style="color:red">{{ data.value }}%</b>
                          <div v-if="data.item.required === data.item.current">{{ data.value }}%</div>
                        </template>
                        <template #cell()="data">
                          {{ data.value }}
                        </template>
                      </b-table>
                      <b-alert show variant="danger" v-if="delegationOutOfRange">
                        <p>Account does not contain enough delegation space for this token's fees. Please change existing delegations to make room for {{ items[0].required }}% fee.</p>
                        <p>This can be done from <a href="https://www.splex.gg">splex.gg</a>.</p>
                      </b-alert>
                    </b-form-group>
                    <b-form-group
                      id="autoclaimleaguechests-enabled-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Claim League Chests"
                      label-for="autoclaimleaguechests-enabled"
                    >
                      <b-form-checkbox v-model="user.account.autoClaimLeagueAdvanceChests" name="autoclaimleaguechests-enabled" switch class="mt-2">Enabled</b-form-checkbox>
                      <b-form-checkbox v-if="user.account.autoClaimLeagueAdvanceChests" v-model="user.account.autoClaimChestsUseLegendaryPotions" name="autoclaimlegendarypotions-enabled" switch class="mt-2">Use Legendary Potions</b-form-checkbox>
                      <b-form-checkbox v-if="user.account.autoClaimLeagueAdvanceChests" v-model="user.account.autoClaimChestsUseGoldPotions" name="autoclaimgoldpotions-enabled" switch class="mt-2">Use Gold Potions</b-form-checkbox>
                    </b-form-group>
                </b-card-text>
              </b-card-body>
              <b-card-body class="pb-0 pt-0" v-if="user.account.enabled">
                <b-card-title>Play</b-card-title>
                <b-card-text>
                  <b-form-group
                    id="format-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Format"
                    label-for="format"
                  >
                  <template #description>
                    <div>Which format to play games in.</div>
                  </template>
                    <span>{{user.play.format}}</span>
                  </b-form-group>
                  <b-form-group
                    v-if="true"
                    id="wildpass-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Auto Buy Wild Pass"
                    label-for="wildpass"
                  >
                    <template #description>
                      <div>Buy a Wild Pass before the first match of a season.</div>
                    </template>
                    <b-form-checkbox v-model="user.play.wildpass.enabled" name="wildPass" switch class="mt-2" />
                  </b-form-group>
                  <b-form-group
                    id="paymentMethods-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Payment Methods"
                    label-for="paymentMethods"
                    v-if="user.play.wildpass.enabled"
                    >
                    <template #description>
                      <div>Which payment methods can be used to buy the pass.</div>
                    </template>
                    <b-form-checkbox-group v-model="user.play.wildpass.paymentMethods">
                      <b-form-checkbox value="DEC"><img  src="../assets/dec.png" width="20" height="20"/> 2000 DEC</b-form-checkbox>
                      <b-form-checkbox value="DEC-B"><img  src="../assets/dec-b.png" width="20" height="20"/> 2000 DEC-B</b-form-checkbox>
                      <b-form-checkbox value="Credits"><img  src="../assets/credits.png" width="20" height="20"/> 2000 Credits</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                  <b-form-group
                    id="paymentOrder-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Payment Order"
                    label-for="paymentOrder"
                    v-if="user.play.wildpass.enabled && user.play.wildpass.paymentMethods.length > 0"
                    >
                    <template #description>
                      <div>Drag to change preferred order of payment. If first method does not have sufficient balance the next will be used.</div>
                    </template>
                    <draggable v-model="user.play.wildpass.paymentMethods" tag="ol" class="payment-methods-list">
                      <li v-for="(method, index) in user.play.wildpass.paymentMethods" :key="method" class="list-group-item">
                        {{ index + 1 }}. {{ method }}
                      </li>
                    </draggable>
                  </b-form-group>
                  <b-form-group
                    id="frequency-group"
                    description="How many times per hour to play."
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Frequency"
                    label-for="frequency"
                  >
                    <b-input-group>
                      <template #append>
                        <b-input-group-text >{{user.play.frequency}}</b-input-group-text>
                      </template>
                      <b-form-input id="frequency" v-model="user.play.frequency" type="range" min="1" max="8"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    id="minCp-group"
                    description="What is the minimum Collection Power (CP) for the account to play?"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="MinCp"
                    label-for="minCp"
                  >
                    <b-input-group>
                      <template #append>
                        <b-input-group-text >{{user.play.minCp}}</b-input-group-text>
                      </template>
                      <b-form-input id="minCp" v-model="user.play.minCp" type="range" min="5000" max="500000" step="1000"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    id="energyTarget-group"
                    description="Play match if above chosen energy."
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Energy Target"
                    label-for="energyTarget"
                  >
                    <b-input-group>
                        <template #append>
                          <b-input-group-text >{{user.play.energyTarget}}</b-input-group-text>
                        </template>
                        <b-form-input id="energyTarget" v-model="user.play.energyTarget" type="range" min="0" max="49"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    id="stopAtRating-group"
                    description="Stop playing at chosen rating. 0 for no max rating."
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Stop At Rating"
                    label-for="stopAtRating"
                  >
                    <b-form-input id="stopAtRating" v-model="user.play.stopAtRating" type="number" min="0"></b-form-input>
                    <b-alert show variant="warning" class="mt-2" v-if="user.play.stopAtRating > 0 && user.play.stopAtRating <= 100">
                      <div class="d-flex">
                        <b-icon icon="exclamation-triangle-fill" scale="2" variant="warning" class="mr-3 mt-2"></b-icon>
                        <div>
                          <p class="mb-0">Warning - Please be sure this setting is correct. The current value will make it stop in novice league. This setting is for what ranked rating to stop the bot at. Such as 1800 to stop in silver.</p>
                        </div>
                      </div>
                    </b-alert>
                  </b-form-group>
                  <!-- <b-form-group
                    id="stopAtBoost-group"
                    description="Play a match if account reward boost is at least chosen amount."
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Minimum Reward Boost"
                    label-for="stopAtBoost"
                  >
                    <b-input-group>
                        <template #append>
                          <b-input-group-text >{{Number(user.play.stopAtBoost).toFixed(2)}}x</b-input-group-text>
                        </template>
                        <b-form-input id="stopAtBoost" v-model="user.play.stopAtBoost" type="range" min="0.00" max="13.00" step="0.25"></b-form-input>
                    </b-input-group>
                  </b-form-group> -->
                  <b-form-group
                    id="minSpsp-group"
                    description="Play a match if account has at least the chosen amount of staked SPS owned, delegated, or rented."
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Minimum Staked SPS"
                    label-for="minSpsp"
                  >
                    <b-form-input id="minSpsp" v-model="user.play.minSpsp" type="number" min="0" max="2000000"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="harderOpponents-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Harder Opponents"
                    label-for="harderOpponents"
                  >
                    <b-form-checkbox v-model="user.play.harderOpponents" name="harderOpponents" switch class="mt-2" />
                    <template #description>
                      Use this if you expect your opponents to have level capped teams for your league.
                    </template>
                  </b-form-group>
                </b-card-text>
              </b-card-body>
              <b-card-body class="pb-2 pt-0" v-if="user.account.enabled">
                <b-card-title>Collection</b-card-title>
                <b-card-text>
                  <b-form-group
                    id="bannedCards-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Banned Cards"
                    label-for="bannedCards"
                  >
                  <template #description>
                    ID of cards which will not be played. See <a href="https://www.archmage.app/card-id-list/" target="_blank">card list</a> to find ID for a card.
                  </template>
                  <b-form-tags
                    input-id="tags-separators"
                    invalid-tag-text="Invalid card ID"
                    duplicate-tag-text="Duplicate card ID"
                    v-model="user.collection.banned"
                    separator=","
                    placeholder="Enter card IDs separated by comma"
                    :tag-validator="bannedCardsValidator"
                    no-add-on-enter
                  ></b-form-tags>
                  </b-form-group>
                </b-card-text>
                <b-form-group
                    id="banStarters-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Ban Starter Cards"
                    label-for="banStarters"
                  >
                    <b-form-checkbox v-model="user.collection.banStarters" name="banStarters" switch class="mt-2" />
                    <template #description>
                      Ban starter cards from the handfinder. <b>Note:</b> A collection must have at least 30 unique playable card IDs including one summoner for this setting to take effect. 
                    </template>
                  </b-form-group>
              </b-card-body>
              <b-card-body class="text-center">
                <b-button variant="primary" v-if="hasHiveKeychain" :disabled="waitingHiveResponse" @click="submitHiveKeychain" class="mr-4">Submit with Hive Keychain</b-button>
                <!--b-button variant="primary" @click="submitHiveSigner">Submit with Hivesigner</b-button-->
                <b-button variant="outline-primary" @click="reset" class="ml-4">Back</b-button>
              </b-card-body>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
  html body {
    background-color: #182323;
  }
  .card {
    background: #e6e2d9 !important;
  }
  
  .card .bg-primary,  .card .btn-primary {
    background-color: #5aa900 !important;
    border-color: #427c00;
  }

  .card .btn-outline-primary {
    color: #5aa900;
    border-color: #427c00;
  }

  .config-link, .config-link:hover {
    color: #5aa900;
  }
</style>

<style scoped>
.payment-methods-list {
  padding-left: 0; /* Remove padding */
  list-style-type: none; /* Remove default list styles */
}

.payment-methods-list .list-group-item {
  display: flex;
  align-items: center;
}
</style>

<script>
import draggable from 'vuedraggable';

var tokenHolders = {
  ARCHMAGEA: new Set(),
  ARCHMAGEB: new Set(),
  ARCHMAGE: new Set(),
  ALL: new Set()
}

var tokenFees = {
  ARCHMAGEA: 15,
  ARCHMAGEB: 25,
  ARCHMAGE: 35,
  NONE: 0
}

const ARCHMAGE_BILLING_ACCOUNT = 'archmage.billing'

async function fetchJson(endpoints, queryParams, requestBody = {}) {
  if (!Array.isArray(endpoints)) {
    endpoints = [endpoints];
  }

  for(var errorRetries = 1; errorRetries <= 5; ++errorRetries) {
    var url = endpoints[(errorRetries - 1) % endpoints.length];
    try {
      var response = await fetch(`${url}${queryParams ? '?': ''}${params(queryParams)}`, requestBody);
      if(response.ok)
        return await response.json();
      else{
        try {
          return await response.json();
        } catch (e) {
          return response.status;
        }
      }
    } catch(e) {
      if(errorRetries == 5)
        throw `Failed to get JSON response from "${url}" after ${errorRetries} attempts. Last exception: ${e}`;
    }
  }
}

function params(object) {
  var encodedString = '';
  for (var prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (encodedString.length > 0) {
        encodedString += '&';
      }
      encodedString += prop + '=' + encodeURIComponent(object[prop]);
    }
  }
  return encodedString;
}

async function updateTokenHolders() {
  var results = [];
  var offset = 0;
  var moreResponses = true;
  const queryLimit = 1000;
  var validTokenTypes = location?.origin.startsWith('https://alpha.archmage.app') ? ['ARCHMAGEA'] : ['ARCHMAGEA','ARCHMAGEB','ARCHMAGE'];
  var endpoints = ['https://herpc.dtools.dev/contracts','https://engine.rishipanthee.com/contracts', 'https://herpc.dtools.dev/contracts', 'https://api.primersion.com/contracts', 'https://herpc.kanibot.com/contracts'];

  while(moreResponses) {
    var postBody = {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({'jsonrpc':'2.0', 'method':'find', 'params':{'contract':'tokens','table':'balances','limit': queryLimit, 'offset': offset, 'query':{'symbol':{$in:validTokenTypes}, "$or": [{'balance': {$ne:'0'}}, {'stake': {$ne:'0'}}, {'delegationsIn': {$ne:'0'}}]}}, 'id':offset + 1})
    };

    let response = await fetchJson(endpoints, null, postBody);
    results = [...results, ...response.result];
    if(response.result.length !== queryLimit)
      moreResponses = false;
    else
      offset += queryLimit;
  }

  tokenHolders.ARCHMAGEA = new Set(results.filter(holder => (holder.balance > 0 || holder.stake > 0 || holder.delegationsIn > 0) && holder.symbol === 'ARCHMAGEA').map(holder => holder.account));
  tokenHolders.ARCHMAGEB = new Set(results.filter(holder => (holder.balance > 0 || holder.stake > 0 || holder.delegationsIn > 0) && holder.symbol === 'ARCHMAGEB').map(holder => holder.account));
  tokenHolders.ARCHMAGE = new Set(results.filter(holder => (holder.balance > 0 || holder.stake > 0 || holder.delegationsIn > 0) && holder.symbol === 'ARCHMAGE').map(holder => holder.account));
  tokenHolders.ALL = new Set(results.filter(holder => holder.balance > 0 || holder.stake > 0 || holder.delegationsIn > 0).map(holder => holder.account));
}

export default {
  components: {
    draggable
  },
  beforeMount: function() {
    this.resetUser();
    this.previousConfigs = JSON.parse(this.$cookie.get('previousConfigs')) || [];
  },
  data: function () {
    return {
      delegationInterval: null,
      step: 1,
      error: null,
      warning: false,
      user: null,
      userStatus: null,
      postingKey: '',
      waitingHiveResponse: false,
      delegationOutOfRange: false,
      awaitingDelegationUpdate: {
        'wild': {value: false, attempts: 0, previousValue: -1},
        'modern': {value: false, attempts: 0, previousValue: -1},
        'focus': {value: false, attempts: 0, previousValue: -1},
        'season': {value: false, attempts: 0, previousValue: -1},
        'license': {value: false, attempts: 0, previousValue: -1},
        'land': {value: false, attempts: 0, previousValue: -1},
        'nightmare': {value: false, attempts: 0, previousValue: -1},
        'brawl': {value: false, attempts: 0, previousValue: -1}
      },
      previousConfigs: [],
      fields: [
        {key: 'icon', label: '', tdClass: 'align-middle'},
        {key: 'type', tdClass: 'align-middle'},
        {key: 'required', label: 'Required', tdClass: 'align-middle' },
        {key: 'current', label: 'Current', tdClass: 'align-middle'},
        {key: 'action', tdClass: 'align-middle' }
      ],
      items: [
        { type: 'Wild', required: 35, current: 0, isRequired: true},
        { type: 'Modern', required: 0, current: 0, isRequired: false},
        { type: 'Focus', required: 0, current: 0, isRequired: false},
        { type: 'Season', required: 0, current: 0, isRequired: false},
        { type: 'License', required: 0, current: 0, isRequired: false},
        { type: 'Land', required: 0, current: 0, isRequired: false},
        { type: 'Nightmare', required: 0, current: 0, isRequired: false},
        { type: 'Brawl', required: 0, current: 0, isRequired: false},
      ],
      delegations: {}
    }
  },
  methods: {
    async hasPostingKey() {
      var postBody = {
        headers: {'x-api-key':'cnCZhCqSPS5megm1WENP3pjcCievTV11YDJytPf6'}
      };
      var result = await fetchJson(`https://v533jx7mf0.execute-api.us-east-2.amazonaws.com/config/users/${this.user.account.name}/status${location?.origin.startsWith('https://alpha.archmage.app') ? '?environment=alpha' : ''}`, null, postBody);
      this.userStatus = result;
    },
    async savePostingKey() {
      if(!this.postingKey || this.postingKey === '')
        return;

      let bodyObj = {posting: this.postingKey};
      if(location?.origin.startsWith('https://alpha.archmage.app'))
        bodyObj.environment = 'alpha';

      var postBody = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-api-key':'cnCZhCqSPS5megm1WENP3pjcCievTV11YDJytPf6' // Well aren't you snoopy :P
        },
        body: JSON.stringify(bodyObj)
      };

      var result = await fetchJson(`https://v533jx7mf0.execute-api.us-east-2.amazonaws.com/config/users/${this.user.account.name}/key`, null, postBody);
      this.postingKey = "";
      if(result.success) {
        await this.hasPostingKey();
      } else {
        let msgContent = this.$createElement('div',null,[
            this.$createElement('p',null,['Failed to save posting key. Please check if posting key is correct.']),
            this.$createElement('p',null,[
              `Details:`,
              this.$createElement('br'),
              `${result.message}`])
          ]);

          this.$bvToast.toast(msgContent, {
            title: 'Failed',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
      }
    },
    async updateDelegations() {
      if(this.step === 2) {
        try {
          let response = await fetchJson('https://api2.splinterlands.com/players/reward_delegations', {username: this.user.account.name})
          if(Array.isArray(response)) {
            let typeTotals = {};

            this.delegations = response.reduce((p,c) => {
              p[c.delegate_to_player] = p[c.delegate_to_player] || {};
              p[c.delegate_to_player][c.type] = Number(c.percent);
              if(c.delegate_to_player !== ARCHMAGE_BILLING_ACCOUNT)
                typeTotals[c.type] = (typeTotals[c.type] || 0) + Number(c.percent);
              return p;
            },{});

            let isDelegationOutOfRange = false;

            for(let type in typeTotals) {
              if(type !== 'wild')
                continue;
              if(typeTotals[type] + tokenFees[this.tokenType] > 100)
                isDelegationOutOfRange = true;
            }

            this.delegationOutOfRange = isDelegationOutOfRange;
          
            for(let item of this.items) {
              if(this.delegations[ARCHMAGE_BILLING_ACCOUNT] && this.delegations[ARCHMAGE_BILLING_ACCOUNT][item.type.toLowerCase()] !== undefined) {
                let currentDelegation = this.delegations[ARCHMAGE_BILLING_ACCOUNT][item.type.toLowerCase()];
                if(currentDelegation)
                  item.current = currentDelegation;
              } else {
                item.current = 0;
                if(this.delegations[ARCHMAGE_BILLING_ACCOUNT])
                  this.delegations[ARCHMAGE_BILLING_ACCOUNT][item.type.toLowerCase()] = 0;
              }
            }

            for(let type in this.awaitingDelegationUpdate) {
              this.awaitingDelegationUpdate[type].attempts++;
              this.awaitingDelegationUpdate[type].value = this.awaitingDelegationUpdate[type].attempts <= 3 && this.delegations[ARCHMAGE_BILLING_ACCOUNT] && this.awaitingDelegationUpdate[type].previousValue === this.delegations[ARCHMAGE_BILLING_ACCOUNT][type];
            }
          }
        } catch(error) {console.log(error)}
      }
    },
    onSubmit(event) {
      event.preventDefault();
      console.log("boop");
    },
    resetUser() {
      this.user = {
        account: {
          enabled: false,
          name: "",
          claimSps: false,
          autoClaimLeagueAdvanceChests: false,
          autoClaimChestsUseLegendaryPotions: false,
          autoClaimChestsUseGoldPotions: false
        },
        play: {
          format: "Wild",
          frequency: 1,
          minCp: 5000,
          energyTarget: 0,
          stopAtRating: 0,
          // stopAtBoost: 0.00,
          minSpsp: 0,
          harderOpponents: false,
          wildpass: {
            enabled: false,
            paymentMethods: []
          }
        },
        collection: {
          banned: [],
          banStarters: false
        }
      }
    },
    nextStep: async function(arg) {
      switch(this.step) {
        case 1:
          this.user.account.name = ((typeof arg === 'string' && arg) || this.user.account.name).toLowerCase().trim();
          this.hasHiveKeychain = window.hive_keychain ? true : false;
          this.error = null;
          this.warning = null;
          await updateTokenHolders();

          this.tokenType =  tokenHolders.ARCHMAGEA.has(this.user.account.name) ? 'ARCHMAGEA' :
                            tokenHolders.ARCHMAGEB.has(this.user.account.name) ? 'ARCHMAGEB' :
                            tokenHolders.ARCHMAGE.has(this.user.account.name) ? 'ARCHMAGE' : 'NONE';

          for(let item of this.items)
            item.required = item.isRequired ? tokenFees[this.tokenType]: 0;

          var config = await fetchJson(`https://archmage${location?.origin.startsWith('https://alpha.archmage.app') ? '-alpha' : ''}-config.s3.amazonaws.com/${this.user.account.name}.json`);

          if(config.account) {
            if(!this.previousConfigs.includes(this.user.account.name)) {
              console.log(JSON.stringify(this.previousConfigs))
              let newConfigs = this.previousConfigs;
              newConfigs.unshift(this.user.account.name);
              newConfigs.splice(20);
              this.previousConfigs = newConfigs;
            }
            else {
              let newConfigs = this.previousConfigs.filter(name => name !== this.user.account.name);
              newConfigs.unshift(this.user.account.name);
              this.previousConfigs = newConfigs;
            }
            this.$cookie.set('previousConfigs', JSON.stringify(this.previousConfigs), 365);
            config.account.claimSps = config.account.claimSps || false;
            delete config.account.maxModernLeague;
            delete config.account.maxLeague;
            delete config.account.autoClaimSeasonReward;
            delete config.quest;
            config.play.energyTarget = config?.play?.energyTarget ?? Math.trunc((config?.play?.ecrTarget || 0) / 2);
            delete config.play.ecrTarget;
            config.play.frequency = Math.max(Math.min(config.play.frequency, 8), 1) || 1;
            config.play.minCp = Math.max(Math.min(config.play.minCp, 500000), 5000) || 5000;
            // config.play.stopAtBoost = Math.max(Math.min(config.play.stopAtBoost, 13.00), 0.00) || 0.00;
            config.play.minSpsp = Math.max(Math.min(config.play.minSpsp, 2000000), 0) || 0;
            config.play.format = 'Wild';
            config.play.wildpass = config.play.wildpass || {enabled: false, paymentMethods: []};
            config.account.autoClaimLeagueAdvanceChests = config.account.autoClaimLeagueAdvanceChests || false;
            config.account.autoClaimChestsUseLegendaryPotions = config.account.autoClaimChestsUseLegendaryPotions || false;
            config.account.autoClaimChestsUseGoldPotions = config.account.autoClaimChestsUseGoldPotions || false;
            delete config.play.modernStopAtRating;
            config.play.harderOpponents = config.play.harderOpponents || false;
            config.collection.banStarters = config.collection.banStarters || false;
            this.user = config;
          } else {
            if(this.tokenType === 'NONE') {
              this.error = `No ARCHMAGE, ARCHMAGEA or ARCHMAGEB token found for user ${this.user.account.name}`;
              return;
            }
          }

          this.step = 2;

          await this.hasPostingKey();
          await this.updateDelegations();
          this.delegationInterval = setInterval(this.updateDelegations, 10000);
          break;
        case 2:
          break;
      }
    },
    reset: function() {
      this.error = null;
      this.warning = false;
      this.resetUser();
      this.step = 1;
      clearInterval(this.delegationInterval);
    },
    create: function() {
      this.step = 2;
    },
    bannedCardsValidator: function(cardId) {
      return Number.isInteger(parseInt(cardId)) && cardId > 0 && cardId < 10000;
    },
    submitHiveKeychain: function() {
      this.user.collection.banned = this.user.collection.banned.map(c => parseInt(c));
      this.user.play.frequency = Number(this.user.play.frequency);
      this.user.play.minCp = Number(this.user.play.minCp);
      this.user.play.energyTarget = Number(this.user.play.energyTarget);
      this.user.play.stopAtRating = Number(this.user.play.stopAtRating);
      // this.user.play.stopAtBoost = Number(this.user.play.stopAtBoost);
      this.user.play.minSpsp = Number(this.user.play.minSpsp);
      this.waitingHiveResponse = true;
      window.hive_keychain.requestCustomJson(this.user.account.name, `archmage_update_config${location?.origin.startsWith('https://alpha.archmage.app') ? '_alpha' : ''}`, 'Posting', JSON.stringify(this.user), `Archmage config for user ${this.user.account.name}`, response => {
        this.waitingHiveResponse = false;
        if(response.success) {
          this.$bvToast.toast('Successfully submitted config.', {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        } else {
          let msgContent = this.$createElement('div',null,[
            this.$createElement('p',null,['Failed to submit config.']),
            this.$createElement('p',null,[
              `Error Message:`,
              this.$createElement('br'),
              `${response.error.message}`])
          ]);

          this.$bvToast.toast(msgContent, {
            title: 'Failed',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        }
      });
    },
    submitHiveKeychainDelegation(percent, type, previousValue) {
      let txJson = {
        username: ARCHMAGE_BILLING_ACCOUNT,
        type: type
      }

      if(percent > 0) {
        txJson.percent = `${percent}`;
      }

      window.hive_keychain.requestCustomJson(this.user.account.name, percent > 0 ? 'sm_delegate_rewards' : 'sm_undelegate_rewards', 'Active', JSON.stringify(txJson), `Delegate ${percent}% ${type} SPS rewards to ${ARCHMAGE_BILLING_ACCOUNT}`, response => {
        if(response.success) {
          this.awaitingDelegationUpdate[type].value = true;
          this.awaitingDelegationUpdate[type].attempts = 0;
          this.awaitingDelegationUpdate[type].previousValue = previousValue;

          this.$bvToast.toast('Successfully submitted delegation transaction.', {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        } else {
          let msgContent = this.$createElement('div',null,[
            this.$createElement('p',null,['Failed to submit delegation transaction.']),
            this.$createElement('p',null,[
              `Error Message:`,
              this.$createElement('br'),
              `${response.error.message}`])
          ]);

          this.$bvToast.toast(msgContent, {
            title: 'Failed',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        }
      });
    },
    submitHivesignerDelegation(percent, type) {
      let txJson = {
        username: ARCHMAGE_BILLING_ACCOUNT,
        type: type
      }

      if(percent > 0) {
        txJson.percent = `${percent}`;
      }

      var signerURL = "https://hivesigner.com/sign/custom_json?" + 
        `authority=active&required_auths=` +
            encodeURIComponent(`["${this.user.account.name}"]`) +
        `&required_posting_auths=` +
            encodeURIComponent(`[]`) +
        `&id=${percent > 0 ? 'sm_delegate_rewards' : 'sm_undelegate_rewards'}&json=` +
            encodeURIComponent(JSON.stringify(txJson));

        window.open(signerURL);
    },
    submitHiveSigner: function() {
      this.user.collection.banned = this.user.collection.banned.map(c => parseInt(c));
      this.user.play.frequency = Number(this.user.play.frequency);
      this.user.play.minCp = Number(this.user.play.minCp);
      this.user.play.energyTarget = Number(this.user.play.energyTarget);
      this.user.play.stopAtRating = Number(this.user.play.stopAtRating);
      // this.user.play.stopAtBoost = Number(this.user.play.stopAtBoost);
      this.user.play.minSpsp = Number(this.user.play.minSpsp);
      var signerURL = "https://hivesigner.com/sign/custom_json?" + 
        `authority=posting&required_auths=` +
            encodeURIComponent(`[]`) +
        `&required_posting_auths=` +
            encodeURIComponent(`["${this.user.account.name}"]`) +
        `&id=archmage_update_config${location?.origin.startsWith('https://alpha.archmage.app') ? '_alpha' : ''}&json=` +
            encodeURIComponent(JSON.stringify(this.user));

        window.open(signerURL);
    }
  }
}
</script>