<template>
  <div id="app">
    <b-container>
      <b-row class="justify-content-md-center mt-4 mb-4">
        <b-col col md="8">
          <b-card header="Splinterlands Player History Rating Graph" header-bg-variant="primary" header-text-variant="white">
            <b-form @submit="onSubmit">
              <b-card-body class="pb-0 pt-2">
                <b-card-text>
                    <b-form-group
                      id="accountName-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label=""
                      label-for="accountName"
                    >
                      <div id="accountName" class="mt-2">
                        <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0 text-center">
                          <b-form-input id="inline-form-input-username" placeholder="Username" v-model="user" @keydown.enter.native="load"></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary" @click="load">
                              <span>Load</span>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-form-group>
                </b-card-text>
              </b-card-body>
            </b-form>
            <b-card-body v-if="user">
              <b-card-header>Modern</b-card-header>
              <div><canvas id="ratingGraphModern"></canvas></div>
              <b-alert v-if="!showModern" show variant="secondary" >No results</b-alert>
            </b-card-body>
            <b-card-body v-if="user">
              <b-card-header>Wild</b-card-header>
              <div><canvas id="ratingGraphWild"></canvas></div>
              <b-alert v-if="!showWild" show variant="secondary" >No results</b-alert>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
  html body {
    background-color: #182323;
  }
  .card {
    background: #e6e2d9 !important;
  }
  
  .card .bg-primary,  .card .btn-primary {
    background-color: #5aa900 !important;
    border-color: #427c00;
  }

  .card .btn-outline-primary {
    color: #5aa900;
    border-color: #427c00;
  }

  .config-link, .config-link:hover {
    color: #5aa900;
  }
</style>

<script>
import Chart from 'chart.js/auto';


var modernGraph;
var wildGraph;


async function fetchJson(url, queryParams, requestBody = {}) {
  for(var errorRetries = 1; errorRetries <= 5; ++errorRetries) {
    try {
      var response = await fetch(`${url}${queryParams ? '?': ''}${params(queryParams)}`, requestBody);
      if(response.ok)
        return await response.json();
      else
        return response.status;
    } catch(e) {
      if(errorRetries == 5)
        throw `Failed to get JSON response from "${url}" after ${errorRetries} attempts. Last exception: ${e}`;
    }
  }
}

function params(object) {
  var encodedString = '';
  for (var prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (encodedString.length > 0) {
        encodedString += '&';
      }
      encodedString += prop + '=' + encodeURIComponent(object[prop]);
    }
  }
  return encodedString;
}

async function getPlayerData(player, format) {
  let response = await fetchJson(`https://api2.splinterlands.com/battle/history?player=${player}&format=${format}`);
  
  if(!response.battles || response.battles.length === 0)
    return [];

  let filterFormat = format === 'wild' ? null : format;

  return response.battles
    .filter(b => b.format === filterFormat && b.match_type === 'Ranked')
    .reverse()
    .map((b,i) => {
      return {index: i + 1, rating: b[`${b.player_1 === player ? 'player_1' : 'player_2'}_rating_final`]};
    });
}

function createChart(id, data) {
  const plugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const {ctx} = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || '#99ffff';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

  // console.log(element);
  return new Chart(
    document.getElementById(id),
    {
      type: 'line',
      data: {
        labels: data.map(row => row.index),
        datasets: [
          {
            borderColor: '#36a2eb',
            label: 'Rating',
            data: data.map(row => row.rating)
          }
        ]
      },
      options: {
        plugins: {
          customCanvasBackgroundColor: {
            color: '#FFFFFF'
          },
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
                    title: function() {
                        return;
                    },
                }
          }
        }
      },
      plugins: [plugin],
    }
  );
}

export default {
  beforeMount: function() {
    Chart.defaults.borderColor = '#000000';
  },
  mounted: function() {
    this.user = this.$route?.query.user ?? undefined;
    this.loadGraph();
  },
  data: function () {
    return {
      user: null,
      showModern: true,
      showWild: true,
      error: null,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    load: async function() {
      this.user = this.user.toLowerCase().trim();

      this.loadGraph();
      
      // this.$bvToast.toast(msgContent, {
      //       title: 'Failed',
      //       variant: 'danger',
      //       toaster: 'b-toaster-bottom-right',
      //       solid: true
      //     });
    },
    loadGraph: async function () {
      if(this.user) {
        this.$router.replace({ name: `ratingGraph`, query: {user: this.user} });
        const modernData = await getPlayerData(this.user, 'modern');
        const wildData = await getPlayerData(this.user, 'wild');

        modernGraph?.destroy();
        wildGraph?.destroy();
        
        if(modernData && modernData.length > 0) {
          let element = document.getElementById('ratingGraphModern');
          element.style.visibility='visible';
          element.style.display='block';
          this.showModern = true;
          modernGraph = createChart('ratingGraphModern', modernData);
        } else {
          let element = document.getElementById('ratingGraphModern');
          this.showModern = false;
          element.style.visibility='hidden';
          element.style.display='none';
        }
        if(wildData && wildData.length > 0) {
          let element = document.getElementById('ratingGraphWild');
          element.style.visibility='visible';
          element.style.display='block';
          this.showWild = true;
          wildGraph = createChart('ratingGraphWild', wildData);
        } else {
          let element = document.getElementById('ratingGraphWild');
          this.showWild = false;
          element.style.visibility='hidden';
          element.style.display='none';
        }
      }
    }
  }
}
</script>