<template>
  <div id="app">
    <b-container>
      <b-row class="justify-content-md-center mt-4 mb-4">
        <b-col col md="8">
          <b-card header="Splinterlands Reward Delegation" header-bg-variant="primary" header-text-variant="white">
            <b-form @submit="onSubmit">
              <b-card-body class="pb-0 pt-2">
                <b-card-text>
                    <b-form-group
                      id="accountName-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="User"
                      label-for="accountName"
                    >
                      <div id="accountName" class="mt-2">
                        <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0 text-center">
                          <b-form-input id="inline-form-input-username" placeholder="Username" v-model="user" :disabled="userLoaded" @keydown.enter.native="loadOrClear"></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary" @click="loadOrClear">
                              <span v-if="!userLoaded">Load</span>
                              <span v-if="userLoaded">Clear</span>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-form-group>
                    <b-form-group
                      id="delegatee-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Delegate To"
                      label-for="delegatee"
                      v-if="userLoaded"
                    >
                      <div id="delegatee" class="mt-2">
                        <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0 text-center">
                          <b-form-input id="inline-form-input-delegatee" placeholder="Username" v-model="delegatee" :disabled="!userLoaded"></b-form-input>
                        </b-input-group>
                      </div>
                    </b-form-group>
                    <b-form-group
                      id="reward-type-group"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      label="Reward Type"
                      label-for="rewardType"
                      v-if="userLoaded"
                    >
                      <b-form-select v-model="rewardType" name="rewardType" @change="onChangeType">
                        <b-form-select-option value="all">All SPS</b-form-select-option>
                        <b-form-select-option value="wild">Wild SPS</b-form-select-option>
                        <b-form-select-option value="modern">Modern SPS</b-form-select-option>
                        <b-form-select-option value="focus">Focus Chest SPS</b-form-select-option>
                        <b-form-select-option value="season">Season Chest SPS </b-form-select-option>
                        <b-form-select-option value="license">Validator License SPS</b-form-select-option>
                        <b-form-select-option value="brawl">Brawl</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                    id="percent-group"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    label="Percentage"
                    label-for="percent"
                    v-if="userLoaded"
                  >
                    <b-input-group id="percent">
                      <template #append>
                        <b-input-group-text >{{percent}}%</b-input-group-text>
                      </template>
                      <b-form-input id="frequency" v-model="percent" type="range" min="0" max="100"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-card-text>
              </b-card-body>
              <b-card-body class="text-center" v-if="userLoaded">
                <b-button variant="primary" v-if="hasHiveKeychain" :disabled="waitingHiveResponse" @click="submitHiveKeychain" class="mr-4">Submit with Hive Keychain</b-button>
                <b-button variant="primary" @click="submitHiveSigner">Submit with Hivesigner</b-button>
              </b-card-body>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
  html body {
    background-color: #182323;
  }
  .card {
    background: #e6e2d9 !important;
  }
  
  .card .bg-primary,  .card .btn-primary {
    background-color: #5aa900 !important;
    border-color: #427c00;
  }

  .card .btn-outline-primary {
    color: #5aa900;
    border-color: #427c00;
  }

  .config-link, .config-link:hover {
    color: #5aa900;
  }
</style>

<script>
async function fetchJson(url, queryParams, requestBody = {}) {
  for(var errorRetries = 1; errorRetries <= 5; ++errorRetries) {
    try {
      var response = await fetch(`${url}${queryParams ? '?': ''}${params(queryParams)}`, requestBody);
      if(response.ok)
        return await response.json();
      else
        return response.status;
    } catch(e) {
      if(errorRetries == 5)
        throw `Failed to get JSON response from "${url}" after ${errorRetries} attempts. Last exception: ${e}`;
    }
  }
}

function params(object) {
  var encodedString = '';
  for (var prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (encodedString.length > 0) {
        encodedString += '&';
      }
      encodedString += prop + '=' + encodeURIComponent(object[prop]);
    }
  }
  return encodedString;
}

export default {
  beforeMount: function() {
    this.resetUser();
  },
  data: function () {
    return {
      user: null,
      userLoaded: true,
      delegatee: '',
      rewardPercentages: {
        wild: 0,
        modern: 0,
        focus: 0,
        season: 0,
        license: 0,
        brawl: 0
      },
      rewardType: 'all',
      percent: 0,
      error: null,
      warning: false,
      waitingHiveResponse: false,
      hasHiveKeychain: false
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    onChangeType() {
      this.percent = this.rewardType !== 'all' ? this.rewardPercentages[this.rewardType] : Math.max(...[0, ...Object.values(this.rewardPercentages)]);
    },
    resetUser() {
      this.user = null;
      this.userLoaded = false;
      this.delegatee = null;
      this.rewardPercentages = {
        wild: 0,
        modern: 0,
        focus: 0,
        season: 0,
        license: 0,
        brawl: 0
      };
      this.percent = 0;
      this.rewardType = 'all';
    },
    loadOrClear: async function() {
      if(this.userLoaded) {
        this.resetUser();
      } else {
        this.hasHiveKeychain = window.hive_keychain ? true : false;
        
        this.user = this.user.toLowerCase().trim();

        if(this.user === "")
          return;
        
        var delegateeHistory = await fetchJson(`https://api2.splinterlands.com/players/reward_delegations?username=${this.user}`);
        if(Array.isArray(delegateeHistory)) {
          for(let type in this.rewardPercentages)
            this.rewardPercentages[type] = delegateeHistory.filter(d => d.type === type && d.token === 'SPS').map(d => d.percent).reduce((_,v) => v, 0);

          this.percent = Math.max(...[0, ...Object.values(this.rewardPercentages)]);
          this.userLoaded = true;
        }
      }
    },
    submitHiveKeychain: function() {
      this.waitingHiveResponse = true;
      let txJson = {
        username: this.delegatee.toLowerCase().trim(),
        percent: `${this.percent}`
      }
      if(this.rewardType !== 'all')
        txJson.type = this.rewardType;

      window.hive_keychain.requestCustomJson(this.user, 'sm_delegate_rewards', 'Active', JSON.stringify(txJson), `Delegate ${this.percent}% ${this.rewardType !== 'all' ? `${this.rewardType} ` : ''}SPS rewards to ${this.delegatee}`, response => {
        this.waitingHiveResponse = false;
        if(response.success) {
          this.$bvToast.toast('Successfully submitted delegation transaction.', {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        } else {
          let msgContent = this.$createElement('div',null,[
            this.$createElement('p',null,['Failed to submit delegation transaction.']),
            this.$createElement('p',null,[
              `Error Message:`,
              this.$createElement('br'),
              `${response.error.message}`])
          ]);

          this.$bvToast.toast(msgContent, {
            title: 'Failed',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        }
      });
    },
    submitHiveSigner: function() {
      let txJson = {
        username: this.delegatee.toLowerCase().trim(),
        percent: this.percent
      }
      if(this.rewardType !== 'all')
        txJson.type = this.rewardType;

      var signerURL = "https://hivesigner.com/sign/custom_json?" + 
        `authority=active&required_auths=` +
            encodeURIComponent(`["${this.user}"]`) +
        `&required_posting_auths=` +
            encodeURIComponent(`[]`) +
        `&id=sm_delegate_rewards&json=` +
            encodeURIComponent(JSON.stringify(txJson));

        window.open(signerURL);
    }
  }
}
</script>